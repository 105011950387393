import React from 'react';
import './index.css';  // Ensure Tailwind CSS is imported
import Header from './components/Header';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div>
      <Header />  {/* Add the header component here */}
      <HomePage />
    </div>
  );
}

export default App;
