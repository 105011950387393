// src/components/Footer.js
import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 py-6 text-center">
      <p className="text-gray-500">&copy; 2024 MAXIE. ALL RIGHTS RESERVED.</p>
    </footer>
  );
}
