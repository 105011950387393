import React from 'react';

export default function Hero() {
  const agents = [
    {
      name: 'Podcast Outreach Agent',
      category: 'Outreach Agents',
      benefits: 'Expand brand awareness by reaching out to top podcasts.',
      color: 'bg-red-500',
    },
    {
      name: 'Link Building Outreach Agent',
      category: 'Outreach Agents',
      benefits: 'Boost SEO rankings with targeted backlinks.',
      color: 'bg-yellow-500',
    },
    {
      name: 'Sales Outreach Agent',
      category: 'Outreach Agents',
      benefits: 'Grow your customer base with effective outreach.',
      color: 'bg-green-500',
    },
    {
      name: 'Keyword Research Agent',
      category: 'SEO and Content Marketing Agents',
      benefits: 'Discover optimal keywords for organic traffic.',
      color: 'bg-blue-500',
    },
    {
      name: 'Competitor Research Agent',
      category: 'SEO and Content Marketing Agents',
      benefits: 'Analyze competitors to maintain your edge.',
      color: 'bg-indigo-500',
    },
    {
      name: 'Article Writer Agent',
      category: 'SEO and Content Marketing Agents',
      benefits: 'Get articles that engage readers and convert.',
      color: 'bg-purple-500',
    },
    {
      name: 'Google Search Network Campaign Agent',
      category: 'Paid Advertising Agents',
      benefits: 'Optimize your Google Ads with expert campaigns.',
      color: 'bg-pink-500',
    },
    {
      name: 'Facebook Ads Brief Creation Agent',
      category: 'Paid Advertising Agents',
      benefits: 'Create compelling ad briefs for high-impact results.',
      color: 'bg-teal-500',
    },
    {
      name: 'Pinterest Pin Creation Agent',
      category: 'Organic Social Agents',
      benefits: 'Increase presence with eye-catching Pinterest pins.',
      color: 'bg-orange-500',
    },
  ];

  return (
    <div className="bg-gray-900 min-h-screen flex items-center py-12">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
          {agents.map((agent, index) => (
            <div
              key={index}
              className={`overflow-hidden rounded-lg ${agent.color} ring-1 ring-white/15 p-8`}
            >
              <div className="p-4">
                <h3 className="text-base font-roboto text-gray-100 mb-2" style={{ fontFamily: 'Roboto', fontSize: '1.5rem' }}>{agent.category}</h3>
                <h2 className="mt-2 text-3xl font-bold font-radio-canada text-white mb-4" style={{ fontFamily: 'Radio Canada', fontSize: '3rem' }}>{agent.name}</h2>
                <p className="mt-2 text-base text-gray-200" style={{ fontFamily: 'Roboto', fontSize: '1.5rem' }}>{agent.benefits}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
